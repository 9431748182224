import { People } from "@mui/icons-material";
import LSModule from "../../PanelComponents/LSModule";
import Logo from "../../PanelComponents/LogoComponent";


export default class DashboardModule extends LSModule{

    render(){
        return <>
            <Logo loading large center style={{marginTop:"3em"}}/>
        </>
    }
}