import { createTheme, ThemeProvider } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import React, { Component } from 'react'

const theme = createTheme({
    palette: {

        mode: 'dark',
        action:{
            active:'#fd7000'
        },
        primary: {
            main: '#fd7000',
          },
        secondary: deepOrange,

      }
  });
export default class LysepThemeProvider extends Component {
    render() {
        return (
            <ThemeProvider  theme={theme}>
                {this.props.children}
            </ThemeProvider >
        )
    }
}

