import './UsersModule.scss'
import {Add, People} from "@mui/icons-material";
import LSModule from "../../PanelComponents/LSModule";
import {Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, TextField} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import UsersTable from "./UsersModule/UsersTable";
import ExclusiveDatagrid from '../../PanelComponents/ExclusiveDatagrid';
function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
function AddUserDialog(props) {
    const [state, setState] = useState({
        username:"",
        usernameErrorField:{
            error:0,
            helperText:""
        },
        password:"",
        passwordErrorField:{
            error:0,
            helperText:""
        },
        passwordrt:"",
        passwordrtErrorField:{
            error:0,
            helperText:""
        },
        email:"",
        emailErrorField:{
            error:0,
            helperText:""
        }
        
    })
    return(
        <>
            <Dialog open={props.open} onClose={props.closeHandle} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add User</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Username"
                            type="username"
                            value = {state.username}
                            {...state.usernameErrorField}
                            onChange={(event)=>{
                                setState({
                                    ...state,
                                    username:event.target.value,
                                    usernameErrorField: state.username.length < 5 ? {error:true,helperText:"Username length must be greater then 5 characters"} : {error:false,helperText:""}
                                })
                            }}
                            fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                                <TextField
                                autoFocus
                                margin="dense"
                                id="email"
                                label="Email Address"
                                type="email"
                                value = {state.email}
                                {...state.emailErrorField}
                                onChange={(event)=>{
                                    setState({
                                        ...state,
                                        email:event.target.value,
                                        emailErrorField: !validateEmail(event.target.value) ? {error:true,helperText:"Please enter correct email address"} : {error:false,helperText:""}
                                    })
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="password"
                                label="Password"
                                type="password"
                                value = {state.password}
                                {...state.passwordErrorField}
                                onChange={(event)=>{
                                    setState({
                                        ...state,
                                        password:event.target.value,
                                        passwordErrorField: event.target.value.length < 6 ? {error:true,helperText:"Password length must be greater then 6 characters"} : {error:false,helperText:""} 
                                    })
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="passwordrt"
                                label="Password Retype"
                                type="password"
                                value = {state.passwordrt}
                                {...state.passwordrtErrorField}
                                onChange={(event)=>{
                                    setState({
                                        ...state,
                                        passwordrt:event.target.value,
                                        passwordrtErrorField: event.target.value != state.password ? {error:true,helperText:"Passwords do not match"} : {error:false,helperText:""} 
                                    })
                                }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.closeHandle} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={(event)=>{
                        if(state.username != "" && state.username.length>=5 && validateEmail(state.email) && state.password.length>=6 && state.password == state.passwordrt){
                            let fmd = new FormData();
                            fmd.append("username",state.username)
                            fmd.append("email",state.email)
                            fmd.append("password",state.password)
                            // awxc({
                            //     method:"POST",
                            //     url:getApiHost("manage/users/adduser"),
                            //     data:fmd,
                            //     headers:{"Content-Type":"multipart/form-data"}
                            // }).then(resp=>{
                            //     if(resp.data.state){
                            //         toast.success("User added")
                            //         props.closeHandle(event)
                            //     }else{
                            //         toast.warn(`User cannot be added. Server Message : ${resp.data.message}`)
                            //     }
                            // }).catch(resp=>{
                            //     toast.error("Connection Error")
                            // })
                        }else{
                            toast.error("Please enter correctly input fields")
                        }
                    }} color="primary">
                        Add User
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default class UsersModule extends LSModule {
    state = {
        isAddUserOpened: false
    }
    
    render() {
        const columns = [
            { 
                field: 'id',
                headerName: 'id',
                width: 90,
                primary:true,
                required:false,
                editable:false
            },
            {
                field: 'username',
                headerName: 'Username',
                width: 150,
                required:true,
                editable:true
            },
            {
                field: 'password',
                headerName: 'Password',
                width: 150,
                required:true,
                editable:true
            }
        ];
        return (
        <Box sx={{ padding:'1em' }} >
            
            <ExclusiveDatagrid
            directory="/api/user"
            title="Users"
            columns={columns}
            >
    
            </ExclusiveDatagrid>
        </Box>
        )
        
    }
}