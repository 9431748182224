import { DataGrid,GridActionsCellItem,GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { Box, Button, Divider, Fab, Modal, Snackbar, TextField, Typography } from '@mui/material'
import { AddOutlined, DeleteOutline, RemoveRedEye, ThreeSixty } from '@mui/icons-material'
import LysepAPI from '../../Contexts/ApiContext'

export default class ExclusiveDatagrid extends Component {
    
    constructor(props){
        super(props)
        this.AllowAdd = this.props.allowAdd ?? true
        this.Directory = props.directory
        this.UsesBackend = !(this.Directory === null || this.Directory === undefined) 
        this.Columns = props.columns
        this.RowIdField = null
        this.Primaries = props.columns.filter(t=>t.primary ?? false).map(t=>t.field)
        this.RowIdField = props.columns.find(t=>t === "id" && t.type === "number")
        this.Editable = props.columns.filter(t=>t.editable ?? false)
        console.log("Primaries",this.Primaries)
        console.log("Editable",this.Editable)
        this.deleteEnabled = this.Primaries.length>0 && this.UsesBackend
        this.addEnabled = this.UsesBackend ? (this.Primaries.length>0 && this.AllowAdd)  : false
        let addContext = {}
        let emptyArgs = {}
        this.Editable.forEach(column => {
            addContext[column.field] = column.type === "number" ? 0 : ""
            emptyArgs[column.field] = column.type === "number" ? 0 : ""
        });
        
        this.state = {
            rows: [],
            rowCount: 0,
            isLoading:false,
            paginationModel:{
                pageSize: 5,
                page: 0,
            },
            addContext:addContext,
            addModalOpen:false,
            snackBarMessage:"",
            snackBarOpen:false,
            search:this.props.search
        }
        
        this.paginationHandle = this.paginationHandle.bind(this)
        this.fetchData = this.fetchData.bind(this)
        this.snackBar=this.snackBar.bind(this)
        this.addModal = this.dataModal.bind(this)
        this.customToolbar  = this.customToolbar.bind(this)
        this.addAccount = this.addAccount.bind(this)
        this.deleteAccount = this.deleteAccount.bind(this)
        this.makeUniqueRows = this.makeUniqueRows.bind(this)
        
    }
    componentDidMount(){
        
        this.fetchData();
        
    }
    makeUniqueRows(rows){
        return this.RowIdField == null ? rows.map(((t,i)=>{return {id:i,...t}})) : rows
    }
    componentDidUpdate(){
        if(this.props.search?.code !== this.state.search?.code){
            console.log("-----",this.props.search,this.state.search)
            this.setState((state)=>{
                return {
                    ...state,
                    search:this.props.search
                }
            },()=>{
                this.fetchData()
            })
        }
    }
    fetchData(){
        if(!this.UsesBackend) return;
        new Promise((resolve,reject)=>{
            LysepAPI.get(this.Directory,{
                params:{
                    limit:this.state.paginationModel.pageSize,
                    offset:this.state.paginationModel.pageSize*this.state.paginationModel.page,
                    ...this.props.search
                }
            }).then(resp=>{
                if(resp.data.state){
                    let res = resp.data.result
                    this.setState((state)=>{
                        return {
                            ...state,
                            isLoading:false,
                            rows:this.makeUniqueRows(res),
                            rowCount:resp.data.result_length_full
                        }
                    },()=>{
                        resolve()
                    })
                }else{
                    this.showMessage("Öğe bulunamadı!")
                }
            }).catch((error)=>{
                reject(error)
            })
        })
        
    }
    deleteAccount(params){
        let args = {}
        this.Primaries.forEach(field=>args[field] = params.row[field])
        console.log("Delete args",args)
        LysepAPI.delete(this.Directory,{
            data:args
        }).then(resp=>{
            if(resp.data.state){
                this.fetchData()
                this.showMessage(resp.data.message);
            }else{
                this.showMessage(`Error occured on delete:${resp.data.message}`);
            }
        })
    }
    paginationHandle(model){
        this.setState((state)=>{
            return {
                ...state,
                isLoading:true,
                paginationModel:model
            }
        },()=>{
            this.fetchData()
        })
    }
    customToolbar(){
        return (
            <GridToolbarContainer>
                <Typography
                    variant='h4'
                    sx={{margin:2}}
                >
                    {this.props.title}
                </Typography>
                <Box sx={{flexGrow:1}}/>
                {this.props.toolbar}
                <Divider sx={{marginX:1}} orientation='vertical'/>
                {this.addEnabled ? 
                (<Fab sx={{margin:2}} color="primary" aria-label="add" 
                        onClick={()=>{this.setState((state)=>{return {...state,addModalOpen:true}})}}
                    >
                    <AddOutlined />
                </Fab>):
                <></>
                }
                

            </GridToolbarContainer>
        )
    }
    showMessage(message){
        this.setState((state)=>{
            return {
                ...state,
                snackBarOpen:true,
                snackBarMessage:message
            }
        },()=>{
        })

    }
    addAccount(){
        let ValidationError = false
        let ValidationMessage = ""
        let args = this.state.addContext
        this.Editable.forEach(t=>{
            if(t.required){
                let notMustbe = ""
                let value = args[t.field] ?? ""
                if(value === notMustbe){
                    ValidationError = true
                    ValidationMessage=`${t.headerName} boş bırakılamaz.`
                }
            }
        })
        if(ValidationError){
            this.showMessage(ValidationMessage)
            return;
        }
        LysepAPI.post(this.Directory,args).then(resp=>{
            if(resp.data.state){
                this.setState((state)=>{
                    return {
                        ...state,
                        addModalOpen:false,
                        ...this.emptyArgs

                    }
                },()=>{
                    this.fetchData();
                    this.showMessage(`Created successfully`)

                })
                

            }else{
                this.showMessage(`Hata: ${resp.data.message}`)
            }
        })
    }
    dataModal(){
        return (
            <Modal
                open={this.state.addModalOpen}
                onClose={()=>{
                    this.setState((state)=>{
                        return {
                            ...state,
                            addModalOpen:false
                        }
                    })
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Ekle
                    </Typography>
                    {/* <TextField id="outlined-basic" sx={{marginY:1}} onChange={(val)=>{this.setState((state)=>{return {...state,code:val.target.value}})}} fullWidth label="Hesap Kodu" variant="outlined" />
                    <TextField id="outlined-basic" sx={{marginY:1}} onChange={(val)=>{this.setState((state)=>{return {...state,name:val.target.value}})}} fullWidth label="Hesap İsmi" variant="outlined" />
                    <TextField id="outlined-basic" sx={{marginY:1}} onChange={(val)=>{this.setState((state)=>{return {...state,description1:val.target.value}})}} fullWidth label="Açıklama 1" variant="outlined" />
                    <TextField id="outlined-basic" sx={{marginY:1}} onChange={(val)=>{this.setState((state)=>{return {...state,description2:val.target.value}})}} fullWidth label="Açıklama 2" variant="outlined" />
                    <TextField id="outlined-basic" sx={{marginY:1}} onChange={(val)=>{this.setState((state)=>{return {...state,description3:val.target.value}})}} fullWidth label="Açıklama 3" variant="outlined" /> */}
                    {
                        this.Editable.map((column,i)=>{
                            return (<TextField id="outlined-basic" sx={{marginY:1}} onChange={(val)=>{this.setState((state)=>{
                                state.addContext[column.field] = val.target.value
                            })}} fullWidth label={column.headerName} variant="outlined" />)
                        })
                    }
                    <Box
                        display="flex"
                        alignItems="end"
                        sx={{marginY:2,width:"100%",justifyContent:"end"}}
                        
                    >
                        <Button onClick={()=>{
                        this.setState((state)=>{
                            return {
                                ...state,
                                addModalOpen:false
                            }
                        })
                    }}>
                            İptal
                        </Button>
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            onClick={this.addAccount}
                            startIcon={<AddOutlined />}
                        >
                            Ekle
                        </Button>
                    </Box>
                </Box>
            
            </Modal>
        )
    }
    snackBar(){
        return (<Snackbar
            anchorOrigin={{vertical:"bottom",horizontal:"right"}}
            open={this.state.snackBarOpen}
            onClose={()=>{
                this.setState((state)=>{
                    return {
                        ...state,
                        snackBarOpen:false
                    }
                })
            }}
            autoHideDuration={5000}
            message={this.state.snackBarMessage}
        />)
    }
    render() {
        let Columns = [
            ...this.Columns
        ]
        let ActionsColumn = {
            field: 'actions',
            type: 'actions',
            width:50,
            sortable:false,
            getActions: (params) => {
                let Actions = []
                if(this.deleteEnabled){
                    Actions.push(<GridActionsCellItem icon={<DeleteOutline/>} onClick={()=>{this.deleteAccount(params)}} label="Delete" />)
                }
                if(this.props.actions?.length>0){
                    Actions = [
                        ...Actions,
                        ...this.props.actions.map(t=>t(params))
                    ]
                }
                return Actions
            }
        }
        if(this.props.startWithActions){
            Columns = [
                ActionsColumn,
                ...Columns
            ]
        }else{
            Columns = [
                ...Columns,
                ActionsColumn
            ]
        }
        return (
        <Box sx={{width:"100%",marginY:2}}>
            
            <DataGrid
                rows={this.UsesBackend ? this.state.rows : this.props.data}
                rowCount={this.UsesBackend ? this.state.rowCount : this.props.data.length}
                columns={Columns}
                isCellEditable={false}
                slots={{
                    toolbar:this.customToolbar
                }}
                autoHeight
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                            page:0
                        },
                    },
                }}
                loading={this.state.isLoading}
                paginationModel={this.state.paginationModel}
                onPaginationModelChange={this.paginationHandle}
                pageSizeOptions={[5]}
                paginationMode='server'
            >
            </DataGrid>
            {this.dataModal()}
            {this.snackBar()}
        </Box>
        
        

        )
    }
}
