import React from 'react';
import ReactDOM from 'react-dom/client';
import './root.scss';
import Router from './Routes/router';
import reportWebVitals from './reportWebVitals';
import LFEStore from './Contexts/SessionProvider';
import { Provider } from 'react-redux';
import LysepThemeProvider from './Contexts/ThemeProvider';
import { CookiesProvider } from 'react-cookie';
import axios from 'axios'
axios.defaults.withCredentials = true;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookiesProvider>
      <Provider store={LFEStore}>
          <Router />
      </Provider>
    </CookiesProvider>
);

