import { useSelector } from "react-redux";
import { Navigate, redirect, useNavigate } from "react-router-dom";


export function Index(){
    const isLogged = useSelector(state => state.session.Authenticated)
    console.log("Index rendered")
    return (
        <>
        {isLogged ? <Navigate to="/panel/dashboard"></Navigate>: <Navigate to="/login"></Navigate>}
        </>
    )
}