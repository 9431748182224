import React, { Component } from 'react'
import './Logo.scss';
export default class Logo extends Component {
    render() {
        return (
            <div className={`lysep-logo${this.props.loading ? " loading" : ""}${this.props.animated ? " animated" : ""}${this.props.small ? " sm" : ""}${this.props.medium ? " md" : ""}${this.props.large ? " lg" : ""}${this.props.xlarge ? " xl" : ""}${this.props.xxlarge ? " xll" : ""}${this.props.true ? " true" : ""}${this.props.reverse ? " reverse" : ""}${this.props.center ? " center" : ""}${this.props.reverse ? " reverse" : ""}${this.props.reverse ? " centerf" : ""}${this.props.className ? ` ${this.props.className}` : ""}`} style={this.props.style?this.props.style : {}}>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 951 951">
                <title>lysep logo turuncu 2</title>
                <path style={{fill:this.props.color}} d="M499.91,24.4C237.3,24.4,24.4,237.3,24.4,499.91c0,.69,0,1.4,0,2.09H191.25c0-171.62,139.13-310.75,310.75-310.75V24.42C501.31,24.4,500.6,24.4,499.91,24.4ZM224.81,642.59,77.48,718.42A477.58,477.58,0,0,0,268.69,915.51l80.27-143A312.08,312.08,0,0,1,224.81,642.59ZM812.75,502c0,171.62-139.13,310.75-310.75,310.75V975.4C763,974.3,974.3,763,975.4,502ZM735.21,86.62,654.14,231A312.21,312.21,0,0,1,778.36,359.76l145.72-75A477.76,477.76,0,0,0,735.21,86.62Z" transform="translate(-24.4 -24.4)"/>
                <path style={{fill:this.props.color}} d="M627.87,434a144.24,144.24,0,0,0-57.52-59.62A143.93,143.93,0,0,0,371.1,564.12,143.92,143.92,0,1,0,627.87,434Z" transform="translate(-24.4 -24.4)"/>
                </svg>
            </div>
        )
    }
}

