import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';


const PrivateRoute = () => {
    let session = useSelector(state=>state.session)
    console.log("Session is",session)
    return session.Authenticated ? <Outlet /> : <Navigate to="/login" />;
}
export default PrivateRoute;