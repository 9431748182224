import './Login.scss';
import React, { Component, useState, useContext, useEffect } from 'react';
import { Navigate, Redirect } from 'react-router-dom';
import Axios from 'axios';
import { Button, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {Login as LoginD} from '../Contexts/Reducers/SessionSlice'
import LysepAPI from '../Contexts/ApiContext';
import LFEStore from '../Contexts/SessionProvider';
import { useCookies } from 'react-cookie';


Axios.defaults.withCredentials = true;
Axios.defaults.timeout = 20000

export default function Login(props){
    let isAuthenticated = useSelector(state=>state.session.Authenticated)
    let [state,setState] =useState({
        username:"",
        password:"",
        error:{
            state:false,
            text:""
        },
        redirectToPanel:false
    })
    let [cookies,setCookie] = useCookies()
    let renderRedirect = ()=>{
        if(isAuthenticated || state.redirectToPanel){
            return (
                <Navigate to='/'/>
            )
        }
        return (<></>)
    }
    let handleSubmit = ()=>{
        let username = state.username
        let password = state.password
        if(username !== "" && password !==""){
            let fData = new FormData();
            fData.append("username",username);
            fData.append("password",password);
                LysepAPI.post("/api/auth/login",{
                    username:username,
                    password:password
                }).then(res=>{
                if(res.data.state){
                    LFEStore.dispatch(LoginD(res.data.user))
                    setCookie("__lfe_state",true)
                    state.redirectToPanel=true
                    setState(state)
                }else{
                    state.error.state=true
                    state.redirectToPanel=false

                    state.error.text="Please enter correct username or password"
                    setState(state)
                }
            })
        }else{
            state.error.state=true
            state.error.text="Please enter correct username or password"
            setState(state)
        }
    }
    return (
        <div id="LoginContainerFluid">
            {renderRedirect()}
            <div className="login-layout">
                <div className="logo-holder">
                    <img alt="mainLogo" className="logo" src="/logo/lysep_logo_raw.svg"/>
                    <h1 className="title">
                        <a className="LYSEP">LYSEP</a>
                        <a>PANEL</a>
                    </h1>
                </div>
                <div className="login-card">
                        <div id="login-form">
                            <div className="username-holder input-holder">
                                <TextField label="Username" onChange={e=>setState({...state,username:e.target.value})} type="text" fullWidth variant="outlined"></TextField>
                            </div>
                            <div className="password-holder input-holder mt-4">
                                <TextField label="Password" onChange={e=>setState({...state,password:e.target.value})} type="password" fullWidth variant="outlined"></TextField>
                            </div>
                            <div className="button-holder">
                                <Button onClick={handleSubmit} >Login</Button>
                            </div>
                            {state.error ?
                            (<div className="error-text">
                                <h1>{state.error.text}</h1>
                            </div>):""}
                            
                        </div>
                </div>
            </div>
        </div>
    )
}



class Login_ extends Component {
    
    constructor(props){
        super(props);
        this.renderRedirect = this.renderRedirect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    state={
        username:"",
        password:"",
        error:{
            state:false,
            text:""
        },
        redirectToPanel:false
    }
    renderRedirect = ()=>{
        
        let isAuthenticated = useSelector(state=>state.session.Authenticated)
        if(isAuthenticated || this.state.redirectToPanel){
            return (
                <Navigate to='/'/>
            )
        }
        return (<></>)
    }
    handleSubmit(){
        let username = this.state.username
        let password = this.state.password
        let state = this.state
        if(username !== "" && password !==""){
            let fData = new FormData();
            fData.append("username",username);
            fData.append("password",password);
                LysepAPI.post("/api/auth/login",{
                    username:username,
                    password:password
                }).then(res=>{
                if(res.data.state){
                    LFEStore.dispatch(LoginD(res.data.user))
                    
                    state.redirectToPanel=true
                    this.setState(state)
                }else{
                    state.error.state=true
                    state.redirectToPanel=false

                    state.error.text="Please enter correct username or password"
                    this.setState(state)
                }
            })
        }else{
            state.error.state=true
            state.error.text="Please enter correct username or password"
            this.setState(state)
        }
    }
    render() {
        return (
            <div id="LoginContainerFluid">
                <this.renderRedirect/>
                <div className="login-layout">
                    <div className="logo-holder">
                        <img alt="mainLogo" className="logo" src="/logo/lysep_logo_raw.svg"/>
                        <h1 className="title">
                            <a className="LYSEP">LYSEP</a>
                            <a>PANEL</a>
                        </h1>
                    </div>
                    <div className="login-card">
                            <div id="login-form">
                                <div className="username-holder input-holder">
                                    <TextField label="Username" onChange={e=>this.setState({...this.state,username:e.target.value})} type="text" fullWidth variant="outlined"></TextField>
                                </div>
                                <div className="password-holder input-holder mt-4">
                                    <TextField label="Password" onChange={e=>this.setState({...this.state,password:e.target.value})} type="password" fullWidth variant="outlined"></TextField>
                                </div>
                                <div className="button-holder">
                                    <Button onClick={this.handleSubmit} >Login</Button>
                                </div>
                                {this.state.error ?
                                (<div className="error-text">
                                    <h1>{this.state.error.text}</h1>
                                </div>):""}
                                
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}
