import './PirateCallModule.scss'
import LSModule from "../../PanelComponents/LSModule";
import {AppBar, Box, Button, Checkbox, CircularProgress, Container, Dialog, Divider, FormControlLabel, Grid, IconButton, InputBase, List, ListItem, ListItemButton, ListItemText, Modal, Paper, Slide, Snackbar, Toolbar, Typography} from "@mui/material";
import ExclusiveDatagrid from '../../PanelComponents/ExclusiveDatagrid';
import { GridActionsCellItem, GridMenuIcon, GridSearchIcon } from '@mui/x-data-grid';
import { CloseFullscreen, Directions, RemoveRedEye, Search } from '@mui/icons-material';
import LysepAPI from '../../../Contexts/ApiContext';
import React from 'react';
import { Backdrop } from '@mui/material';

function CheckedSearch(props){
    return (
        <Paper
          component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%'}}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={props.title}
            inputProps={{ 'aria-label': props.title }}
            value={props.textValue}
            onChange={props.textOnChange}
          />
          
          <Divider sx={{ height: 28, m: 0.5,mr:2 }} orientation="vertical" />
          <FormControlLabel color="success" labelPlacement='end' style={{marginTop:"0.1rem"}} control={<Checkbox onChange={props.checkboxOnChange} value={props.checkboxValue} />} label="Strict" />
        </Paper>
      );
}

export default class PirateCallModule extends LSModule {
    state = {
        isAddUserOpened: false,
        result: [],
        queryPool:{
        },
        loading:false,
        showInfoModal:false,
        target: {

        }
    }
    constructor(props){
        super(props)
        this.fetch = this.fetch.bind(this)
    }
    queryPoolTextValue(variable){
        return this.state[variable]
    }
    queryPoolCheckValue(variable){
        return this.state[variable+"_strict"]
    }
    queryPoolTextState(variable,value){
        let injection = {}
        injection[variable] = value === "" ? undefined : value
        this.setState(state=>{
            return {
                ...state,
                queryPool:{
                    ...state.queryPool,
                    ...injection
                }
            };
        })
    }
    queryPoolCheckboxState(variable,value){
        let injection = {}
        injection[variable+"_strict"] = !value ? undefined : false
        this.setState(state=>{
            return {
                ...state,
                queryPool:{
                    ...state.queryPool,
                    ...injection
                }
            };
        })
    }
    fetch(){
        if(Object.keys(this.state.queryPool).length === 0){
            console.log("wtf r duin")
            return;
        }
        LysepAPI.post("/api/piratecall/query",this.state.queryPool).then(res=>{
            console.log(res,"res")
            this.setState(state=>{
                return {
                    ...state,
                    result:res.data.results
                }
            })
        })
    }
    dataModal(){
        let handleClose = ()=>{
            this.setState(state=>{
                return {
                    ...state,
                    showInfoModal:false
                }
            })
        }
        return (
            <Dialog
                fullScreen
                open={this.state.showInfoModal}
                onClose={handleClose}
                // TransitionComponent={React.forwardRef(function Transition(props, ref) {
                //     return <Slide direction="up" ref={ref} {...props} />;
                //   })}
            >
                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    {this.state.target?.TC} - {this.state.target?.ADI} {this.state.target?.SOYADI} Detayları
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleClose}>
                    Kapat
                    </Button>
                </Toolbar>
                </AppBar>
                <Grid container spacing={2} >
                    <Grid item  md={6} xs={12}>
                        <List>
                            <ListItemButton>
                                <ListItemText primary="TC" secondary={this.state.target?.TC} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="ADI" secondary={this.state.target?.ADI} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="SOYADI" secondary={this.state.target?.SOYADI} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="DOGUMTARIHI" secondary={this.state.target?.DOGUMTARIHI} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="ANNEADI" secondary={this.state.target?.ANNEADI} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="ANNETC" secondary={this.state.target?.ANNETC} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="BABAADI" secondary={this.state.target?.BABAADI} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="BABATC" secondary={this.state.target?.BABATC} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="NUFUSIL" secondary={this.state.target?.NUFUSIL} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="NUFUSILCE" secondary={this.state.target?.NUFUSILCE} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="UYRUK" secondary={this.state.target?.UYRUK} />
                            </ListItemButton>
                        </List>
                    </Grid>
                    <Grid item md={6} xs={12} my={2}>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Araçlar
                        </Typography>
                        <Divider></Divider>
                        <List>
                            <ListItemButton>
                                <ListItemText primary="Herhangi bir araç bulunamadı" />
                            </ListItemButton>
                        </List>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Ebeveynler
                        </Typography>
                        <Divider></Divider>
                        <List>
                            {
                                this.state.parents?.length>0 ? (
                                    <>
                                        {this.state.parents.map((parent,i)=>(
                                            <ListItemButton onClick={()=>{this.TargetAim(parent)}} key={i}>
                                                <ListItemText primary={`TC:${parent?.TC}`} secondary={`${parent?.ADI} ${parent?.SOYADI}`}/>
                                            </ListItemButton>
                                        ))}
                                    </>
                                ) : (<ListItemButton>
                                    <ListItemText primary="Herhangi bir ebeveyn bulunamadı" />
                                </ListItemButton>)
                            }
                        </List>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Çocuklar
                        </Typography>
                        <Divider></Divider>
                        <List>
                            {
                                this.state.childs?.length>0 ? (
                                    <>
                                        {this.state.childs.map((child,i)=>(
                                            <ListItemButton onClick={()=>{this.TargetAim(child)}} key={i}>
                                                <ListItemText primary={`TC:${child?.TC}`} secondary={`${child?.ADI} ${child?.SOYADI}`}/>
                                            </ListItemButton>
                                        ))}
                                    </>
                                ) : (<ListItemButton>
                                    <ListItemText primary="Herhangi bir çocuk bulunamadı" />
                                </ListItemButton>)
                            }
                        </List>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Telefon Numaraları
                        </Typography>
                        <Divider></Divider>
                        <List>
                            {
                                this.state.numbers?.length>0 ? (
                                    <>
                                        {this.state.numbers.map((number,i)=>(
                                            <ListItemButton LinkComponent={'a'} key={i}>
                                                <ListItemText primary={<a href={`tel:+90${number?.GSM}`}>{`+90${number?.GSM}`}</a>} />
                                            </ListItemButton>
                                        ))}
                                    </>
                                ) : (<ListItemButton>
                                    <ListItemText primary="Herhangi bir çocuk bulunamadı" />
                                </ListItemButton>)
                            }
                        </List>
                        
                    </Grid>
                </Grid>
            </Dialog>
        )
    }
    loadingScreen(){
        return (<Backdrop
            sx={(theme) => ({  zIndex: theme.zIndex.drawer + 1 })}
            open={this.state.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>)
    }
    showMessage(message){
        this.setState(state=>{
            return {
                ...state,
                snackBarOpen:true,
                snackBarMessage:message
            }
        })
    }
    snackBar(){
        return (<Snackbar
            anchorOrigin={{vertical:"bottom",horizontal:"right"}}
            open={this.state.snackBarOpen}
            onClose={()=>{
                this.setState((state)=>{
                    return {
                        ...state,
                        snackBarOpen:false
                    }
                })
            }}
            autoHideDuration={5000}
            message={this.state.snackBarMessage}
        />)
    }
    TargetAim(newTarget){
        let TargetAimReal =async ()=>{
            let row = newTarget
            if(row === undefined){
                this.showMessage("Hata");
                return;
            }
            let PostDialog = (parents,childs,numbers)=>{
                this.setState(state=>{
                    return {
                        ...state,
                        showInfoModal:true,
                        target:row,
                        parents:parents,
                        childs:childs,
                        numbers:numbers,
                        loading:false
                    }
                })
            }
            try {
                let relationships = (await LysepAPI.post("/api/piratecall/relationship",{TC:row.TC})).data;
                let numbers = (await LysepAPI.post("/api/piratecall/query/gsm/tc",{TC:row.TC})).data;
                if(!relationships.state){
                }
                if(!numbers.state){
                }
                let parents_ = relationships.parents ?? []
                let childs_ = relationships.childs ?? []
                let numbers_ = numbers.results ?? []
                PostDialog(parents_,childs_,numbers_)

            } catch (error) {
                this.showMessage("Hata veri çekilemedi",error)
                console.error(error)
            }
            }

        this.setState((state)=>{
            return {
                ...state,
                loading:true,
                showInfoModal:false
            }
        },()=>{
            TargetAimReal()
        })
    }
    render() {
        console.log(this.state.queryPool)
        const columns = [
            {
                field: 'TC',
                headerName: 'TC',
                width: 150,
                primary:true
            },
            {
                field: 'ADI',
                headerName: 'ADI',
                width: 150,
            },
            {
                field: 'SOYADI',
                headerName: 'SOYADI',
                width: 150,
            },
            {
                field: 'DOGUMTARIHI',
                headerName: 'DOĞUM TARİHİ',
                width: 150,
            },
            {
                field: 'ANNEADI',
                headerName: 'ANNE ADI',
                width: 150,
            },
            {
                field: 'BABAADI',
                headerName: 'BABA ADI',
                width: 150,
            },
            {
                field: 'NUFUSIL',
                headerName: 'NÜFUS İL',
                width: 150,
            },
            {
                field: 'NUFUSILCE',
                headerName: 'NÜFUS İLÇE',
                width: 150,
            },
            {
                field: 'UYRUK',
                headerName: 'DOĞUM TARİHİ',
                width: 150,
            }
        ];
        return (
            <>
                    <Box sx={{ padding:'1em' }} >
                            <Paper elevation={2}>
                                <Box width={"100%"}>
                                    <Grid container spacing={3} paddingX={2} paddingY={4}>
                                        <Grid item xs={12}>
                                            <Typography variant='h3' textAlign={'center'}>PIRATE CALL</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckedSearch
                                                title="TC"
                                                textValue={this.queryPoolTextValue("TC")}
                                                textOnChange={e=>this.queryPoolTextState("TC",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("TC",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("TC")}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CheckedSearch
                                            
                                                title="ADI"
                                                textValue={this.queryPoolTextValue("ADI")}
                                                textOnChange={e=>this.queryPoolTextState("ADI",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("ADI",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("ADI")}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CheckedSearch
                                                title="SOYADI"
                                                textValue={this.queryPoolTextValue("SOYADI")}
                                                textOnChange={e=>this.queryPoolTextState("SOYADI",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("SOYADI",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("SOYADI")}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckedSearch
                                                title="DOĞUM TARİHİ"
                                                textValue={this.queryPoolTextValue("DOGUMTARIHI")}
                                                textOnChange={e=>this.queryPoolTextState("DOGUMTARIHI",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("DOGUMTARIHI",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("DOGUMTARIHI")}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CheckedSearch
                                            
                                                title="ANNE ADI"
                                                textValue={this.queryPoolTextValue("ANNEADI")}
                                                textOnChange={e=>this.queryPoolTextState("ANNEADI",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("ANNEADI",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("ANNEADI")}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CheckedSearch
                                                title="ANNE TC"
                                                textValue={this.queryPoolTextValue("ANNETC")}
                                                textOnChange={e=>this.queryPoolTextState("ANNETC",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("ANNETC",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("ANNETC")}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CheckedSearch
                                            
                                                title="BABA ADI"
                                                textValue={this.queryPoolTextValue("BABAADI")}
                                                textOnChange={e=>this.queryPoolTextState("BABAADI",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("BABAADI",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("BABAADI")}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CheckedSearch
                                                title="BABA TC"
                                                textValue={this.queryPoolTextValue("BABATC")}
                                                textOnChange={e=>this.queryPoolTextState("BABATC",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("BABATC",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("BABATC")}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CheckedSearch
                                            
                                                title="NÜFUS İL"
                                                textValue={this.queryPoolTextValue("NUFUSIL")}
                                                textOnChange={e=>this.queryPoolTextState("NUFUSIL",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("NUFUSIL",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("NUFUSIL")}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CheckedSearch
                                                title="NÜFUS İLÇE"
                                                textValue={this.queryPoolTextValue("NUFUSILCE")}
                                                textOnChange={e=>this.queryPoolTextState("NUFUSILCE",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("NUFUSILCE",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("NUFUSILCE")}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckedSearch
                                                title="UYRUK"
                                                textValue={this.queryPoolTextValue("UYRUK")}
                                                textOnChange={e=>this.queryPoolTextState("UYRUK",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("UYRUK",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("UYRUK")}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckedSearch
                                                title="GSM"
                                                textValue={this.queryPoolTextValue("GSM")}
                                                textOnChange={e=>this.queryPoolTextState("GSM",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("GSM",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("GSM")}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                        <Button variant="contained" startIcon={<Search />} onClick={this.fetch}>
                                            ARAMA YAP
                                        </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        <ExclusiveDatagrid
                            title="Sonuçlar"
                            columns={columns}
                            data={this.state.result}
                            startWithActions={true}
                            actions={[
                                (params)=><GridActionsCellItem icon={<RemoveRedEye/>} onClick={()=>{this.TargetAim(params.row)}} label="Details" />
                            ]}
                        >
                
                        </ExclusiveDatagrid>
                    </Box>
                    {this.snackBar()}
                    {this.dataModal()}
                    {this.loadingScreen()}
        </>
        )
        
    }
}